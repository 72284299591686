html{
  font-family: 'Poppins', sans-serif !important;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
}
h1,h2,h3,h4,h5,h6,p,span,strong, input, select{
  font-family: 'Poppins', sans-serif !important;
}
code {
}
.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.d-flexx-center{
  display: flex;
justify-content: space-between;
align-items: center;
}
.p-color-green{
  color:rgb(134, 212, 115);
}
.p-color-red{
  color:rgb(246,110,101);
}
.border-blue-top{
  border-top:4px solid rgb(1 115 212);
  /* box-shadow:2px 7px 12px 2px lightgrey; */
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 10px;
}
.color-blue{
 color: rgb(1,115,212);
}
.button-action .cancel-order {
 text-transform: capitalize !important;
 color: #ffffff !important;
 background: linear-gradient(to right, #F4463D, rgb(205,4,2)) !important;
 border-radius: 20px !important;
 padding: 5px 14px !important;
 font-size: 14px !important;
 margin: 5px !important;
 font-weight: bold;
 font-family: 'Poppins', sans-serif;
}
.btn-dashboard-blue-color{
 background: linear-gradient(to right, #208ce9, rgb(1 115 212)) !important;
 text-transform: capitalize !important;
 color: #ffffff !important;
 border-radius: 20px !important;
 padding: 5px 14px !important;
 font-size: 14px !important;
 margin: 5px !important;
 font-weight: bold;
 font-family: 'Poppins', sans-serif;
}
.sup-red{
  color:rgb(205,4,2);
}
.zoom {
  font-size: 1.3vw;
  transition: transform 0.2s linear;
  cursor: all-scroll
}